// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/status-badge.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/status-badge.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

import { Colors } from "~/types/base.types";
function getStatusBadgeStyles(color = Colors.GRAY) {
  const statusDefaultStyles = 'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset';
  const statusStylesMap = {
    'green': 'bg-green-50 text-green-700 ring-green-600/20',
    'blue': 'bg-blue-50 text-blue-700 ring-blue-700/10',
    'red': 'bg-red-50 text-red-700 ring-red-600/10',
    'yellow': 'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
    'gray': 'bg-gray-50 text-gray-700 ring-gray-500/10',
    'orange': 'bg-orange-50 text-orange-700 ring-orange-700/10',
    'purple': 'bg-purple-50 text-purple-700 ring-purple-700/10',
    'pink': 'bg-pink-50 text-pink-700 ring-pink-700/10',
    'black': 'bg-black text-neutral-200 ring-black/10',
    'white': 'bg-white text-neutral-900 ring-black/10'
  };
  return statusDefaultStyles + ' ' + statusStylesMap[color];
}
export function PLStatusBadge({
  color,
  text,
  isActive = true,
  onClick
}) {
  const statusStyles = getStatusBadgeStyles(color);
  return <span className={statusStyles + (isActive ? '' : ' opacity-50 ') + (onClick ? ' cursor-pointer' : '')} onClick={onClick}>{text}</span>;
}
_c = PLStatusBadge;
var _c;
$RefreshReg$(_c, "PLStatusBadge");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;